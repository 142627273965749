/* eslint-disable eqeqeq */
import { CancelButton, SaveCardButton, SubmitButton } from '../../input/Buttons'
import { AuthStateContext, DispatchContext, LookupContext } from '../../store'
import { Paper, FormControl, Menu, MenuItem, Select, } from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../../input/Label'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import SalespersonSelection from '../../Contacts/Contact/Salesperson/SalespersonSelection'
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'

import { formatDate, returnDate, safeInterval } from '../../common/helpers'
import { permissions, permissionValues } from '../../constants/permissions'
import { GET_LATEST_INVENTORY_NO } from '../Queries'
import {  } from 'react-router-dom'
import DuplicateInventoryNumberModal from '../DuplicateInventoryNumberModal'
import { useLazyQuery } from '@apollo/client'
import { formatDuration, intervalToDuration, isValid } from 'date-fns'
import sortBy from 'lodash/sortBy'
import GalleryContactEditMode from '../../common/GalleryContactEditMode'

import { FindModeInput } from '../../navigation/Tabs/TabbedPage'
import { Skelly } from '../../common/components/Skelly'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import MultiTypeModal from '../../common/MultiTypeModal'
import SourceLabel from './SourceLabel'
import Source from './Source'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

const INC = Symbol('Increment')

const ArtDetail = (props) => {

	const lookup = useContext(LookupContext)
	const artCategories = lookup.data?.getArtCategories
	const sortedCategories = sortBy(artCategories, [function(o) { return o.value }])
	const insurances = lookup.data?.getInsurance
	const sortedInsurances = sortBy(insurances, [function(o) { return o.value }])

	const [state, setState] = useState(initialState)
	const [artInput, setArtInput] = useState({})
	const [salespersonModal, setSalespersonModal] = useState(false)

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const [canViewSP, setCanViewSP] = React.useState(true)
	const [canEditSP, setCanEditSP] = React.useState(false)
	const [dupeInventoryModal, setDupeInventoryModal] = React.useState(false)

	const [newInvNoModal, setNewInvNoModal] = useState(false)
	const [categoryModal, setCategoryModal] = useState(false)
	const [proposedInvNo, setProposedInvNo] = useState(0)

	const [attempt, setAttempt] = useState(false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}

	const createdUserId = props.art?.created_by
	const currentUserId = authState?.user?.id
	useEffect(() => {
		if (userPermissions) {
			const permissionGalleryContact = userPermissions.find(e => e.permission_id == permissions.ART_SALESPEOPLE)?.permission_value_id
			if (permissionGalleryContact < permissionValues.VIEW_ONLY) setCanViewSP(false)
			const canUpdateGalleryContacts = (permissionGalleryContact == permissionValues.CREATE_AND_EDIT) || 
				(permissionGalleryContact == permissionValues.CREATE_AND_EDIT_OWN && 
					createdUserId == currentUserId)
			setCanEditSP(canUpdateGalleryContacts)
		}
	}, [userPermissions, createdUserId, currentUserId])

	const resetArtInput = (art) => {

		const salespersons = art.salesperson?.map((sp) => ({
			id: sp.id,
			first_name: sp.first_name,
			last_name: sp.last_name,
			is_lead: sp.is_lead,
			is_deleted: sp.is_deleted,
			user_handle: sp.user_handle,
			imgUrl: sp.imgUrl
		}))

		const primary_salespersons = salespersons?.filter(e => e.is_lead)
		if (art && art.id) {

			setArtInput({
				id: art.id,
				inventory_number_prefix: art.inventory_number_prefix,
				inventory_number: art.inventory_number,
				inventory_number_suffix: art.inventory_number_suffix,
				other_inventory_number: art.other_inventory_number,
				insurance_id: art.insurance_id || '-',
				categories: art.categories || [],
				salespersons,
				primary_salespersons,
				catalogue_raisonne: art.catalogue_raisonne
			})
		}
	}

	useEffect(() => resetArtInput(props.art), [props.art])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.art?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const handleChange = (e) => updateField(e.target.name, e.target.value)

	const openNewInvNoModal = () => {
		if (props.findMode) return
		setNewInvNoModal(true)
		getLatestInventoryNo({
			variables: {
				query: artInput.inventory_number_prefix
			}
		})
	}

	const cataloguePermission = userPermissions
		?.find(e => e.permission_id == permissions.CATALOGUE_RAISONNE)
		?.permission_value_id
	const catalogueEditable = cataloguePermission > permissionValues.VIEW_ONLY
	const catalogueSearchable = cataloguePermission >= permissionValues.VIEW_ONLY

	const handleSubmit = (event, newNum) => {

		setAttempt(true)

		if (attempt && (artInput?.categories.length === 0)) {
			openSnackbar(
				severity.WARNING,
				'Please fill fields in red.'
			)
			// check required fields.
		} else if (artInput?.categories.length) {


			let tempArtInput = {...artInput}

			// if called from modal, inc inventory number
			if (event == INC) {
				tempArtInput.inventory_number = newNum
			}

			if (tempArtInput.primary_salespersons) {
				tempArtInput.primary_salesperson_ids = tempArtInput.primary_salespersons.map(e => e.id)
				delete tempArtInput.primary_salespersons
			}

			if (tempArtInput.salespersons) {
				tempArtInput.salespersons_ids = tempArtInput.salespersons.map(e => e.id)
				delete tempArtInput.salespersons
			}

			if (tempArtInput.insurance_id === '-') {
				tempArtInput.insurance_id = null
			}
			
			if (tempArtInput.categories.length) {
				tempArtInput.categories = tempArtInput.categories.map(c => ({ 
					art_category_id: Number(c.id), 
					ordinal: Number(c.ordinal)
				}) )
			}

			if (!catalogueEditable || (tempArtInput.catalogue_raisonne == props.art.catalogue_raisonne)) {
				delete tempArtInput.catalogue_raisonne
			}

			if (tempArtInput.inventory_number_prefix == '') {
				tempArtInput.inventory_number_prefix = null
			}

			if (tempArtInput.inventory_number_suffix == '') {
				tempArtInput.inventory_number_suffix = null
			}

			if (!canEditSP) {
				delete tempArtInput.primary_salesperson_ids
				delete tempArtInput.salespersons_ids
			}
			props.onSave(tempArtInput).then((resp) => {

				const {data} = resp
				const response = data?.updateArtPiece

				if (!response) return

				if (response.success && response.art) {
					handleClose()
				} else {
					const errMsg = response?.message
					if (errMsg.includes('Inventory number') &&
							errMsg.includes('already exists')) {
						setDupeInventoryModal(tempArtInput.inventory_number)
					}
				}
			})
		}
	}

	const [ getLatestInventoryNo, { loading: loadingLatestInvNo }] = useLazyQuery(GET_LATEST_INVENTORY_NO, {
		onCompleted: ({ getLatestInventoryNo }) => {
			const invNo = getLatestInventoryNo ? Number(getLatestInventoryNo) + 1 : 1
			setProposedInvNo(String(invNo))
		}
	})

	const currentLocation = props.art.art_location?.find((art) => art.is_current)
	const changedDate = returnDate(currentLocation?.location_change_at)

	const age = isValid(changedDate) ? intervalToDuration(safeInterval({
		start: changedDate,
		end: new Date(),
	})) : {}

	const getCurrentLocation = () =>{

		let returnable =  [
			currentLocation?.location?.name,
			currentLocation?.sub_location?.name,
			formatDate(currentLocation?.location_change_at, 'MMM do, yyyy')
		].filter(e => e && e !== '-').join(" / ")

		if (currentLocation?.location_change_at) {

			const duration = formatDuration(age,
				{ format: ['years', 'months', 'weeks', 'days', ]},
				{ delimiter: ', '}
			)

			// Checks for same day edge case
			if (duration) {
				returnable += ` (${duration})`
			}
		}

		return returnable
	}


	const updateField = (field, value) => {
		setArtInput({
			...artInput,
			[field]: value
		})
	}
	const editable = state.editable || props.findMode

	const currentObject = artInput


	// Inventory number fields
	let InvNoDisplayValue = `${artInput.inventory_number_prefix ? `${artInput.inventory_number_prefix}-` : ''}${artInput?.inventory_number || ''}${props.art?.inventory_number_suffix ? `-${props.art?.inventory_number_suffix}` : ''}`
	let InvNoLabel = () => <Label
		disableAnimation
		shrink
		htmlFor="art-inventory"
		style={{
			overflow: 'visible',
		}}
	>
		Inventory No.
	</Label>
	let InvNoFields = () => <FormControl>
		<InvNoLabel />
		{props.loading ? <Skelly error={props.error} /> :
			<TextBox
				id="art-shrink"
				value={InvNoDisplayValue}
				readOnly={true}
				data-testid="art-detail-inventory-number"
			/>
		}
	</FormControl>

	const sortedDropdownCategories = sortedCategories?.filter(cat => 
		!artInput?.categories?.find(it => it.id == cat.id)
	)

	const getInventoryNumberDetails = useCallback(() => {

		if (props.findMode) return (
			<FormControl>
				<InvNoLabel />
				<FindModeInput field="searchable_inventory_number" />
			</FormControl>
		) 

		if (!editable) return <InvNoFields />

		if (editable) return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline'
				}}
			>
				<FormControl>
					<InvNoLabel />
					{props.loading ? <Skelly error={props.error} /> :

						<TextBox
							step="any"
							name="inventory_number_prefix"
							value={currentObject.inventory_number_prefix || ''}
							onChange={handleChange}
							onBlur={() => {
								if (currentObject.inventory_number_prefix !== props.art.inventory_number_prefix)
									openNewInvNoModal()
							}}
							data-testid="art-detail-inventory-1"
						/>
					}
				</FormControl>
				<span style={{
					padding: '0 0.5em',
				}} >-</span>

				<TextBox
					disabled
					step="any"
					name="inventory_number"
					value={currentObject.inventory_number || ''}
					data-testid="art-detail-inventory-2"
				/>

				<span style={{
					padding: '0 0.5em',
				}} >-</span>

				<TextBox
					disabled
					step="any"
					name="inventory_number_suffix"
					value={currentObject.inventory_number_suffix || ''}
					data-testid="art-detail-inventory-3"
				/>
			</div>
		)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editable, props.findMode, props.loading, currentObject])

	return (
		<>
			<Paper
				className="qv-margin"
				onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
				id="art-details"
				data-testid="card-details"
			>
				<h1 className="card-title">
					<span>Details</span>
					{state.editable && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									setAttempt(false)
									resetArtInput(props.art)
									handleClose()
								}}
							>
								Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={props.disabled}
								onClick={handleSubmit}
							>
								Save
							</SaveCardButton>
						</>
					)}
				</h1>

				<div className="column-body">
					<div className="column">

						{getInventoryNumberDetails()}

						<FormControl className="padded-select">
							<Label
								disableAnimation
								shrink
								id="insurance-label"
								htmlFor="art-insurance"
							>
								Insurance
							</Label>
							{props.loading ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="insurance" /> :
									<Select
										IconComponent={ExpandMoreRoundedIcon}
										name="insurance_id"

										labelId="insurance-label"
										className={
											!state.editable ? 'readonly' : null
										}
										input={<Dropdown />}
										readOnly={!state.editable}
										value={insurances ? artInput?.insurance_id || '' : ''}
										onChange={handleChange}
										data-testid="art-detail-insurance"
									>
										<MenuItem value="-">-</MenuItem>
										{sortedInsurances?.map((insurance) => (
											<MenuItem
												key={insurance.id}
												value={insurance.id}
											>
												{insurance.value}
											</MenuItem>
										))}
									</Select>
								)
							}
						</FormControl>

						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="art-other-inv-no"
							>
								Other Inventory No.
							</Label>
							{ props.loading ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="otherInventoryNumber" /> : <TextBox
									id="art-other-inv-no"
									multiline
									name="other_inventory_number"
									value={ currentObject.other_inventory_number || '' }
									readOnly={!editable}
									placeholder={'-'}
									onChange={handleChange}
									data-testid="art-detail-inventory-other"
								/>)
							}
						</FormControl>

						{ props.findMode ?
							<div style={{
								display: 'flex',
								justifyContent: 'space-between'
							}}>
								<FormControl style={{ flexGrow: 1}}>
									<Label disableAnimation shrink
										htmlFor="location" >
										Current location
									</Label>
									<FindModeInput field="location_name" />
								</FormControl>
								<span style={{margin: '0 1em'}} />
								<FormControl style={{ flexGrow: 1}}>
									<Label disableAnimation shrink
										htmlFor="sub-location" >
										Sub location
									</Label>
									<FindModeInput field="sub_location_name" />
								</FormControl>
							</div> :
							<FormControl>
								<Label disableAnimation shrink htmlFor="location" >
								Current location
								</Label>
								{props.loading ? <Skelly error={props.error} /> :
									<TextBox
										id="art-other-inv-no"
										multiline
										name="location"
										value={getCurrentLocation()}
										readOnly={true}
										placeholder={'-'}
										onChange={handleChange}
										data-testid="art-detail-location"
									/>
								}
							</FormControl>
						}
						<FormControl className="padded-select">
							<Label id="cr-label" disableAnimation shrink>
								Project Code
							</Label>
							{props.loading ? <Skelly error={props.error} /> :
								(props.findMode ?
									<FindModeInput field="project_code" /> :
									<TextBox
										id="art-project-code"
										name="project_code"
										value={ props.art.project_code || '—' }
										readOnly={true}
									/>)
							}
						</FormControl>
					</div>
					<div className="column">
						<FormControl className="padded-select">
							<Label id="cr-label" disableAnimation shrink>
								Catalogue Raisonné
							</Label>
							{props.loading ? <Skelly error={props.error} /> :
								((props.findMode && catalogueSearchable) ?
									<FindModeInput field="catalogue_raisonne" /> :
									<TextBox
										id="art-catalogue-raisonne"
										name="catalogue_raisonne"
										value={ currentObject.catalogue_raisonne || '' }
										readOnly={!(state.editable && catalogueEditable)
										&& !(props.findMode && catalogueSearchable)}
										placeholder={'-'}
										onChange={handleChange}
										data-testid="art-catalogue-raisonne"
										inputProps={{ maxLength: 50 }}
									/>)
							}
						</FormControl>

						<FormControl>
							<Label id="categories-label" disableAnimation shrink 
								htmlFor="art-categories"
								error={attempt && !artInput.categories.length}
							>
								Categories{!props.findMode && '*'}
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> : 
								(props.findMode ? <FindModeInput field="art_categories" /> :
									<TextBox
										error={attempt && !artInput.categories.length}
										inputProps={{ spellCheck: 'false' }}
										id="art_categories"
										value={artInput.categories?.sort((a, b) => a.ordinal - b.ordinal)?.map(item => item.value).join(", ") || ""}
										readOnly={!state.editable}
										placeholder={'-'}
										onClick={(e) => {
											if (state.editable) {
												setCategoryModal(true)
											}
										}}
										data-testid="art-categories"
									/>
								)
							}
						</FormControl>


						{props.findMode ? 		
							<>
								<FormControl>
									<Label disableAnimation shrink>
										Current Source
									</Label>
									<FindModeInput field="source_name.current_sources"/>
								</FormControl>
								<FormControl>
									<Label disableAnimation shrink>
										Purchaser
									</Label>
									<FindModeInput field="source_name.purchaser"/>
								</FormControl>
								<FormControl>
									<Label disableAnimation shrink>
										Consignor
									</Label>
									<FindModeInput field="source_name.consignor"/>
								</FormControl>
								<FormControl>
									<Label disableAnimation shrink>
										Owner
									</Label>
									<FindModeInput field="source_name.owners"/>
								</FormControl>
						
							</> : <FormControl>
								<Label disableAnimation shrink htmlFor="art-owner"
									style={{ display: 'flex' }}
								>
									<SourceLabel 
										loading={props.loading} 
										sources={props.art?.current_sources} 
									/>
								</Label>

								<Source 
									loading={props.loading} 
									sources={props.art?.current_sources} 
									enableNav={!state.editable}
									thumbnailStyle={{
										margin: '8px auto 8px 8px',
										paddingTop: state.editable ? '1.5em' : null 
									}}
									buttonStyles={{
										marginTop: '1.7em'
									}}
									avatarGroupStyles={{
										marginTop: '2em',
										marginLeft: '0.5em'
									}}
									editTooltip={state.editable}
								/>
							</FormControl>
						}


						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="art-salesperson"
							>
								Gallery Contacts
							</Label>
							{props.loading ? <Skelly error={props.error} /> :
								( props.findMode ? <FindModeInput field="gallery_contacts" /> :
									<GalleryContactEditMode
										setSalespersonModal={setSalespersonModal}
										salespersons={artInput.salespersons}
										canViewSP={canViewSP}
										editable={state.editable && canEditSP}
									/>
								)
							}
						</FormControl>
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose('edit')}>
						Edit
					</MenuItem>
				</Menu>
			</Paper>
			<TransitionsModal
				className="salesperson-modal"
				open={salespersonModal}
				close={() => setSalespersonModal(false)}
			>
				<SalespersonSelection
					input={artInput}
					setInput={setArtInput}
					salespersons={artInput.salespersons}
					primary_salespersons={artInput.primary_salespersons}
					close={() => setSalespersonModal(false)}
				/>

			</TransitionsModal>
			<DuplicateInventoryNumberModal
				isOpen={!!dupeInventoryModal}
				close={() => setDupeInventoryModal(false)}
				number={dupeInventoryModal}
				prefix={artInput.inventory_number_prefix}
				onSave={(newNum) => handleSubmit(INC, newNum)}
			>
			</DuplicateInventoryNumberModal>
			<TransitionsModal
				open={newInvNoModal}
				close={() => {}}
			>
				<form
					className={"modal-small-content"}
					onSubmit={e => {
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<h1 className="card-title">
						<span>New Inventory Number?</span>
					</h1>
					{
						loadingLatestInvNo ? <div>Loading...</div> :
							<div>
								You’re changing the inventory number from {
									(props.art?.inventory_number_prefix ?
										props.art?.inventory_number_prefix+'-' : '') +
								props.art?.inventory_number +
								(props.art?.inventory_number_suffix ?
									'-'+props.art?.inventory_number_suffix : '')
								} to {artInput.inventory_number_prefix}-{proposedInvNo}.
							</div>
					}
					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '1em' }}
						onClick={e => {
							setArtInput({
								...artInput,
								inventory_number_prefix: props.art?.inventory_number_prefix,
								inventory_number: props.art?.inventory_number,
								inventory_number_suffix: props.art?.inventory_number_suffix,
							})
							e.preventDefault()
							setNewInvNoModal(false)
						}}
					>
						Cancel
					</CancelButton>

					<SubmitButton
						style={{ float: 'right', marginTop: '1em' }}
						type="submit"
						variant="contained"
						onClick={e => {
							setArtInput({
								...artInput,
								inventory_number: proposedInvNo,
								inventory_number_suffix: null
							})
							setNewInvNoModal(false)
						}}
					>
					Confirm
					</SubmitButton>
				</form>
			</TransitionsModal>

			<MultiTypeModal
				sortedTypeDropdownValues={sortedDropdownCategories}
				attempt={attempt}
				setAttempt={setAttempt}
				typeModal={categoryModal}
				setTypeModal={setCategoryModal}
				loading={props.loading}
				error={props.error}
				isPrivate={false}
				entityInput={artInput}
				setEntityInput={setArtInput}
				type="value"
				typeId="id"
				keyName="categories"
				title="Category"
				width="15em"
			/>

		</>
	)
}

export default ArtDetail
