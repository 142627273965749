/* eslint-disable eqeqeq */
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import { DispatchContext } from '../store'
import {
	FormControl, Select, MenuItem, IconButton
} from '@mui/material'
import { getArtStatusChip } from '../common/helpers'
import isEqual from 'lodash/isEqual'
import { LookupContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { SubmitButton, CancelButton } from '../input/Buttons'
import {
	GET_LATEST_INVENTORY_NO,
	CREATE_ART_PIECE, SIMPLE_SEARCH_ARTISTS
} from './Queries.js'
import { useMutation, useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import CircularProgress from '@mui/material/CircularProgress'
import DimensionSelection from './ArtPiece/DimensionSelection'
import Dropdown from '../input/Dropdown/Dropdown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../input/Label'
import QuickView from '../QuickView/QuickView'
import React, { useContext } from 'react'
import ReactQuill from 'react-quill'
import SalespersonSelection from '../Contacts/Contact/Salesperson/SalespersonSelection'
import TextBox from '../input/Text/TextBoxThin'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import UserToggleSmall from '../input/Toggle/UserToggleSmall'
import { truthyObj } from '../common/helpers'
import { AuthStateContext } from '../store'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import AvatarInput from '../common/AvatarInput'
import DuplicateInventoryNumberModal from './DuplicateInventoryNumberModal'
import sortBy from 'lodash/sortBy'
import { quillNoNewlines, quillToolbar } from '../constants/values'
import { DEFAULT_IMAGE_USAGE_RIGHT } from '../Notes/ObjectNotes/constants'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'
import MultiTypeModal from '../common/MultiTypeModal'
import { useNavigate } from 'react-router-dom'

const ART_IMAGE_TYPE_ID = 1

const INC = Symbol('Increment')


/**
 * @typedef {Object} CreateArtQuickViewProps
 * @property {function} setCreateArt
 */

/**
 * 
 * @param {CreateArtQuickViewProps} props 
 * @returns 
 */
const CreateArtQuickView = (props) => {

	const navigate = useNavigate()
	const [quillText, setQuillText] = React.useState(null)
	const [attempt, setAttempt] = React.useState(false)
	const [artInput, setArtInput] = React.useState({
		// Caption
		title: '',
		formatted_title: { ops: [] },
		artist: null,
		year: '',
		medium: '',
		all_dimensions: '',
		dimensions: [],
		signature: { ops: [] },
		edition_copy_number: '',
		edition_copy_total: '',
		edition_ap: '',

		// Details
		inventory_number: '',
		inventory_number_prefix: 'LGD',

		insurance_id: '-',
		other_inventory_number: '',
		categories: [],
		salespersons: [],
		primary_salespersons: [],
		catalogue_raisonne: null,

		status_id: 2,
		is_verified: false,
		profile_link: null,
		images: []
	})

	React.useEffect(() => {
		if (quillText && quillText.editor) {
			const editor = quillText.getEditor()
			editor.format('italic','true')
		}
	}, [quillText])

	const [artistOpen, setArtistOpen] = React.useState(false)
	const [artistOptions, setArtistOptions] = React.useState([])
	const [loadArtist, setLoadArtist] = React.useState(false)
	const artistLoading = artistOpen && !!loadArtist

	const [dimensionModal, setDimensionModal] = React.useState(false)
	const [categoryModal, setCategoryModal] = React.useState(false)
	const [dupeInventoryModal, setDupeInventoryModal] = React.useState(false)

	const lookup = useContext(LookupContext)
	const userAuthentication = useContext(AuthStateContext)

	const artStatus = lookup.data?.getArtStatuses
	const insurances = lookup.data?.getInsurance
	const artCategory = lookup.data?.getArtCategories
	const sortedArtCategories = sortBy(artCategory, [function(o) { return o.value }])

	const [salespersonModal, setSalespersonModal] = React.useState(false)

	const [createArtPiece, {loading}] = useMutation(CREATE_ART_PIECE)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}

	const loadComplete = (resp) => {
		const getLatestInventoryNo = resp.getLatestInventoryNo ||
			resp.data?.getLatestInventoryNo
		const invNo = getLatestInventoryNo ? Number(getLatestInventoryNo) + 1 : 1
		setArtInput({
			...artInput,
			inventory_number: String(invNo)
		})
	}
	const { refetch } = useQuery(GET_LATEST_INVENTORY_NO,
		{ variables: { query: 'LGD' }, onCompleted:loadComplete })

	React.useEffect(() => {
		let active = true

		if (!artistLoading) {
			return undefined
		}


		props.client
			.query({
				query: SIMPLE_SEARCH_ARTISTS,
				variables: { query: loadArtist },
			})
			.then((result) => {
				if (active) {
					setLoadArtist(false)
					setArtistOptions(result.data.simpleSearchArtists)
				}
			})


		return () => {
			active = false
		}
	}, [artistLoading, loadArtist, props.client])

	const handleChange = (e) => {
		setArtInput({
			...artInput,
			[e.target.name]: e.target.value,
		})
	}

	const handleSubmit = (event, newNum) => {
		if (event != INC) {
			event.preventDefault()
			event.stopPropagation()
		}
		setAttempt(true)
		
		if (!artInput.title || !artInput.artist_id || artInput?.categories?.length == 0) {
			if (attempt) {
				openSnackbar(
					severity.WARNING,
					'Please fill fields in red.'
				)
			} else {
				return
			}
		} else {

			// Delete unused data
			const tempArtInput = truthyObj(artInput)
			delete tempArtInput.artist
			delete tempArtInput.all_dimensions

			// if called from modal, inc inventory number
			if (event == INC) {
				tempArtInput.inventory_number = newNum
			}
			for (var i in tempArtInput) {
				if (
					tempArtInput[i] === '-' ||
						(Array.isArray(tempArtInput[i]) &&
							tempArtInput[i].length === 0)
				) {
					delete tempArtInput[i]
				}
			}

			if (tempArtInput.salespersons) {
				tempArtInput.salespersons_ids = tempArtInput.salespersons.map(
					(e) => e.id
				)
				delete tempArtInput.salespersons
			}

			if (tempArtInput.primary_salespersons) {
				tempArtInput.primary_salesperson_ids = tempArtInput.primary_salespersons.map(
					(e) => e.id
				)
				delete tempArtInput.primary_salespersons
			}

			if (isEqual(tempArtInput.signature, { ops: [] }))
				delete tempArtInput.signature

			if (tempArtInput.dimensions) {
				tempArtInput.dimension = tempArtInput.dimensions
				delete tempArtInput.dimensions
			}

			if (tempArtInput.is_verified) {
				tempArtInput.verified_by =
						userAuthentication.user.id
				tempArtInput.verified_at = new Date()
			}

			if (tempArtInput.images?.[0]) {
				delete tempArtInput.images[0].src
				tempArtInput.images[0].ordinal = 1
			}

			if (tempArtInput.categories?.length) {
				tempArtInput.categories = tempArtInput.categories.map(c => ({ 
					art_category_id: Number(c.id), 
					ordinal: Number(c.ordinal)
				}) )
			}

			createArtPiece({
				variables: {
					CreateArtPieceInput: tempArtInput,
				},
			})
				.then((res) => {

					setAttempt(false)
					if (res.data?.createArtPiece?.success) {
						setAttempt(false)
						openSnackbar(severity.SUCCESS, 'Artwork created successfully.')
						props.setCreateArt(null)

						navigate(`/art/${res.data.createArtPiece.art.id}/details`)

					} else {
						setAttempt(false)
						const errMsg = res.data?.createArtPiece?.message
						if (errMsg.includes('Inventory number') &&
								errMsg.includes('already exists')) {
							setDupeInventoryModal(tempArtInput.inventory_number)
						}
						let message = 'Could not create artwork.'
						if (errMsg) message += ` ${errMsg}`
						openSnackbar( severity.ERROR, message)
					}
				})
				.catch((error) => {
					console.error(error)
					openSnackbar( severity.ERROR, 'There was an error creating the artwork.')
				})
		}
	}

	const sortedDropdownCategories = sortedArtCategories?.filter(cat => 
		!artInput.categories.find(it => it.id == cat.id)
	)

	return (
		<QuickView className="quick-view-main">

			<form
				style={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
				onSubmit={handleSubmit}
			>
				<div className="quick-view-inner">

					<div style={{ width: '100%' }}>

						<h2 className="card-title">Create New ArtWork</h2>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<FormControl>
								<Label
									disableAnimation
									shrink
									focused={false}
									sx={{
										overflow: 'visible'
									}}
								>
									Inventory Number
								</Label>

								<TextBox
									step="any"
									name="inventory_number_prefix"
									value={artInput.inventory_number_prefix}
									onChange={handleChange}
									onBlur={() =>
										refetch({ query: artInput.inventory_number_prefix })
											.then(loadComplete)
									}
									data-testid="art-inventory-1"
								/>
							</FormControl>

							<span style={{
								marginTop: '1.2em',
								padding: '0 0.5em',
							}} >-</span>

							<FormControl>
								<Label disableAnimation shrink />
								<TextBox
									disabled
									step="any"
									name="inventory_number"
									value={artInput.inventory_number}
									data-testid="art-inventory-2"
								/>
							</FormControl>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								paddingTop: '1em',
							}}
						>
							<Select
								inputProps={{
									style: {
										addingLeft: '0'
									}
								}}
								
								input={<Dropdown />}
								IconComponent={ExpandMoreRoundedIcon}
								className="hideborder small-selection"
								value={
									artInput?.status_id && artStatus
										? parseInt(artInput?.status_id)
										: ''
								}
								renderValue={(id) =>
									getArtStatusChip({status_id: id}, false, artStatus, true)
								}
								onChange={(event) => {
									setArtInput({
										...artInput,
										status_id: event.target.value,
									})
								}}
								data-testid="art-status"
							>
								{artStatus &&
								artStatus.map((type) => (
									<MenuItem
										key={`${type.id}-${type.value}`}
										value={type.id}
										style={{ cursor: 'pointer' }}
										data-testid="art-status-item"
									>
										{getArtStatusChip({status_id: type.id}, false, artStatus, true)}
									</MenuItem>
								))}
							</Select>

							<div
								style={{
									marginTop: '2px',
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column'
								}}
							>
								{artInput.is_active ? (
									<span
										style={{
											color: 'var(--available)',
											fontWeight: '500',
										}}
									>
									ACTIVE
									</span>
								) : (
									<span
										style={{
											color: 'var(--sold)',
											fontWeight: '500',
										}}
									>
									INACTIVE
									</span>
								)}
								<UserToggleSmall
									checked={artInput.is_active || false}
									onClick={() =>
										setArtInput({
											...artInput,
											is_active: !artInput.is_active,
										})
									}
									data-testid="art-active"
								/>
							</div>
							<IconButton
								onClick={() =>
									setArtInput({
										...artInput,
										is_verified: !artInput.is_verified,
									})
								}
								data-testid="art-verified"
								size="large">
								{artInput?.is_verified ? (
									<img
										alt="verified-art"
										style={{
											width: '24px',
											height: '24px',
										}}
										src="/images/icons/VerifiedIcons/VerifiedBlueCheck.svg"
									/>
								) : (
									<img
										alt="unverified-art"
										style={{
											width: '24px',
											height: '24px',
										}}
										src="/images/icons/VerifiedIcons/UnverifiedBlueX.svg"
									/>
								)}
							</IconButton>
						</div>
						<FormControl fullWidth data-testid="art-title">
							<Label
								disableAnimation
								shrink
								htmlFor="art-title"
								error={attempt && !artInput?.title}
							>
							Title*
							</Label>

							<ReactQuill
								theme="snow"
								className={
									attempt && !artInput?.title ? 'error' : null
								}
								ref={(el) => {
									if (el) setQuillText(el)
								}}
								value={artInput.formatted_title}
								modules={quillNoNewlines}
								onChange={(content, delta, source, editor) => {
									setArtInput({
										...artInput,
										formatted_title: editor.getContents(),
										title: editor.getText().trim(),
									})
								}}

							/>
						</FormControl>

						<div style={{ display: 'flex'}}>

							<FormControl className="artist-formcontrol" style={{flexGrow: 2}}>
								<Label
									disableAnimation
									shrink
									htmlFor="art-artist"
									error={attempt && !artInput?.artist_id}
								>
								Artist*
								</Label>

								<LimitedAutocomplete

									query={loadArtist}
									setQuery={setLoadArtist}

									autoHighlight
									forcePopupIcon
									filterSelectedOptions
									popupIcon={<ExpandMoreRoundedIcon />}
									classes={autoCompleteErrorStyles()}
									open={artistOpen && !!artistOptions?.length}
									onOpen={() => setArtistOpen(true)}
									onClose={() => setArtistOpen(false)}
									getOptionLabel={(option) => {
										return `${option.first_name} ${option.last_name}`
									}}
									options={artistOptions}
									loading={artistLoading}
									value={artInput.artist}
									isOptionEqualToValue={(option, value) =>
										option.id == value.id
									}
									onChange={(event, value) => {
										if (value === null) {
											setArtInput({
												...artInput,
												artist: null,
												artist_id: null,
											})
										} else {
											setArtInput({
												...artInput,
												artist: {
													id: value.id,
													first_name: value.first_name,
													last_name: value.last_name,
												},
												artist_id: value.id,
											})
										}
									}}
									renderInput={(params) => (
										<TextBoxThinForAutocomplete
											{...params}
											id="artist-autocomplete"
											variant="outlined"
											fullWidth
											error={attempt && !artInput?.artist_id}
											style={{ paddingTop: '1.5em' }}
											classes={{ notchedOutline: null }}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{artistLoading ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
												style: {
													paddingTop: '0px',
													paddingBottom: '0px',
												},
											}}
										/>
									)}
									data-testid="art-artist"
								/>
							</FormControl>

						</div>

						<FormControl className="fullWidth  padded-select">
							<Label id="category-label" disableAnimation shrink error={attempt && !artInput?.categories?.length}>
								Categories*
							</Label>

							<TextBox
								error={attempt && !artInput.categories.length}
								inputProps={{ spellCheck: 'false' }}
								id="art_categories"
								value={artInput.categories?.sort((a, b) => a.ordinal - b.ordinal)?.map(item => item.value).join(", ") || ""}
								placeholder={'-'}
								onClick={(e) => setCategoryModal(true)}
								data-testid="art-categories"
							/>
						</FormControl>

						<AvatarInput
							style={{
								textAlign: 'center',
								marginTop: '1em',
								marginBottom: '1em'
							}}
							newEntity={true}
							alt={artInput?.title || "New Art Piece"}
							src={artInput?.imgUrl}
							editable={true}
							oldProfileLink={[]}
							inputName="CreateArtPieceInput"
							entityId={null}
							setEntity={setArtInput}
							entity={artInput}
							entityName="ArtPiece"
							includeImages={true}
							extraFields={{
								type_id: ART_IMAGE_TYPE_ID,
								usage_right_id: DEFAULT_IMAGE_USAGE_RIGHT
							}}
							creation={true}
						/>


						<FormControl fullWidth>
							<Label disableAnimation shrink htmlFor="art-year">
							Date
							</Label>

							<TextBox
								name="year"
								value={artInput.year}
								onChange={handleChange}
								data-testid="art-year"
							/>
						</FormControl>

						<FormControl fullWidth>
							<Label disableAnimation shrink htmlFor="art-medium">
							Medium
							</Label>

							<TextBox
								step="any"
								name="medium"
								value={artInput.medium}
								onChange={handleChange}
								data-testid="art-medium"
							/>
						</FormControl>

						<FormControl className="fullWidth">
							<Label
								disableAnimation
								shrink
								htmlFor="art-dimension"
							>
							Dimensions
							</Label>

							<TextBox
								multiline
								id="art-dimension"
								value={artInput.all_dimensions}
								onClick={(e) => {
									if (isEqual(artInput.dimensions, [])) {
										setArtInput({
											...artInput,
											dimensions: [{ description: '', ordinal: 1 }],
										})
									}
									setDimensionModal(true)
								}}
								data-testid="art-dimension"
							/>
						</FormControl>

						<FormControl fullWidth data-testid="art-signed">
							<Label disableAnimation shrink htmlFor="art-Signed">
							Signed
							</Label>

							<ReactQuill
								theme="snow"
								className="quill-qv"
								value={artInput.signature}
								modules={quillToolbar}
								onChange={(content, delta, source, editor) => {
									setArtInput({
										...artInput,
										signature: editor.getContents(),
									})
								}}
							/>
						</FormControl>

						<>
							<Label style={{
								marginLeft: 0,
								marginBottom: '-12px',
								marginTop: '-5px'
							}} disableAnimation shrink htmlFor="art-edition">
							Edition
							</Label>

							<div style={{ display: 'flex' }}>
								<TextBox
									name="edition_copy_number"
									value={artInput.edition_copy_number}
									placeholder="#"
									onChange={handleChange}
									endAdornment={
										<span style={{ padding: '5px' }}>of</span>
									}
									data-testid="art-edition_number"
								/>
								<TextBox
									name="edition_copy_total"
									value={artInput.edition_copy_total}
									placeholder="#"
									onChange={handleChange}
									data-testid="art-edition_total"
								/>
							</div>

							<div style={{ display: 'flex', paddingTop: '1em'}}>
								<TextBox
									name="edition_ap"
									value={artInput.edition_ap}
									placeholder="AP #"
									endAdornment={
										<span style={{ padding: '5px' }}>of</span>
									}
									onChange={handleChange}
									data-testid="art-edition_ap"
								/>
								<TextBox
									name="edition_ap_total"
									value={artInput.edition_ap_total}
									placeholder="Total AP"
									onChange={handleChange}
									data-testid="art-edition_ap_total"
								/>
							</div>

							<FormControl fullWidth>
								<Label
									disableAnimation
									shrink
									htmlFor="art-other_inventory_number"
								>
								Other Inventory Number
								</Label>

								<TextBox
									step="any"
									name="other_inventory_number"
									value={artInput.other_inventory_number}
									onChange={handleChange}
									data-testid="art-other_inventory_number"
								/>
							</FormControl>

							<FormControl className="fullWidth padded-select">
								<Label id="insurance-label" disableAnimation shrink>
								Insurance
								</Label>

								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="insurance_id"
									
									labelId="insurance-label"
									className="medium-selection"
									input={<Dropdown />}
									value={artInput?.insurance_id || ''}
									onChange={handleChange}
									data-testid="art-insurance"
								>
									<MenuItem value="-">-</MenuItem>
									{insurances?.map((insurance) => (
										<MenuItem
											key={insurance.id}
											value={insurance.id}
											data-testid="art-insurance-item"
										>
											{insurance.value}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl style={{ width: '100%' }}>
								<Label
									disableAnimation
									shrink
									htmlFor="art-gallery-contacts"
								>
								Gallery Contacts
								</Label>

								<TextBox
									multiline
									inputProps={{ spellCheck: 'false' }}
									id="art-gallery-contacts"
									value={
										artInput.salespersons
											?.map(
												(salesperson) =>
													salesperson.first_name +
												' ' +
												salesperson.last_name
											)
											.join(', ') || ''
									}
									onClick={(e) => {
										setSalespersonModal(true)
									}}
									data-testid="art-gallery-contacts"
								/>
							</FormControl>
							<FormControl fullWidth>
								<Label
									disableAnimation
									shrink
									htmlFor="catalogue_raisonne"
								>
								Catalogue Raisonné
								</Label>

								<TextBox
									step="any"
									name="catalogue_raisonne"
									value={artInput.catalogue_raisonne}
									onChange={handleChange}
									data-testid="catalogue_raisonne"
									inputProps={{ maxLength: 50 }}
								/>
							</FormControl>
						</>
					</div>
				</div>

				<div
					style={{
						paddingTop: '1em',
						marginBottom: '1em',
						marginLeft: '1em',
						marginRight: '1em',
						marginTop: 'auto',
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={(e) => {
							props.setCreateArt(null)
							setAttempt(false)
						}}
						data-testid="art-cancel"
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						data-testid="art-submit"
						disabled={loading}
					>
						Submit
					</SubmitButton>
				</div>
			</form>

			<TransitionsModal
				className="dimension-modal"
				open={dimensionModal}
				close={() => {
					setDimensionModal(false)
					setArtInput({
						...artInput,
						dimensions: artInput.dimensions?.filter(e => e.description),
						all_dimensions: artInput.dimensions
							?.filter((e) => e.description)
							?.map((e) => e.description)
							.join('\n'),
					})
				}}
			>
				<DimensionSelection
					artInput={artInput}
					setArtInput={setArtInput}
					setDimensionModal={setDimensionModal}
					attempt={attempt}
				/>
			</TransitionsModal>

			<TransitionsModal
				className="salesperson-modal"
				open={salespersonModal}
				close={() => setSalespersonModal(false)}
			>
				<SalespersonSelection
					input={artInput}
					setInput={setArtInput}
					salespersons={artInput.salespersons || []}
					primary_salespersons={artInput.primary_salespersons || []}
					close={() => setSalespersonModal(false)}
				/>
			</TransitionsModal>
			<DuplicateInventoryNumberModal
				isOpen={!!dupeInventoryModal}
				close={() => setDupeInventoryModal(false)}
				number={dupeInventoryModal}
				prefix={artInput.inventory_number_prefix}
				onSave={(newNum) => handleSubmit(INC, newNum)}
			>
			</DuplicateInventoryNumberModal>

			<MultiTypeModal
				sortedTypeDropdownValues={sortedDropdownCategories}
				attempt={attempt}
				setAttempt={setAttempt}
				typeModal={categoryModal}
				setTypeModal={setCategoryModal}
				loading={props.loading}
				error={props.error}
				isPrivate={false}
				entityInput={artInput}
				setEntityInput={setArtInput}
				type="value"
				typeId="id"
				keyName="categories"
				title="Category"
				width="15em"
			/>

		</QuickView>
	)
}

export default withApollo(CreateArtQuickView)
