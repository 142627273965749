/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material'
import camelCase from 'lodash/camelCase'
import Label from '../input/Label'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Dropdown from '../input/Dropdown/Dropdown'
import { CancelButton, SubmitButton } from '../input/Buttons'

import { severity } from '../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../store'
import TextBox from '../input/Text/TextBox'

import ArtAppraisal from './ArtAppraisal'
import ArtDetailOptions from './ArtDetailOptions'
import { permissions, permissionValues } from '../constants/permissions'
import InventoryReportOptions from './InventoryReportOptions'
import FramingReportOptions from './FramingReportOptions'
import { fetchWithAuth } from '../common/helpers'
import { useParams } from 'react-router-dom'
import FaceSheetModal from './FaceSheetInput'
import ClientProfile from './ClientProfile'
import ArtLabelModal from './ArtLabelSheet'
import ImageReportOptions from './ImageReportOptions'

export const EXPORT_URL = '/api/generate'

const exportTemplates = {
	'Framing': {
		type: 'Art',
		compression: {width: 768, height: 768}
	},
	'Inventory Report': {
		type: 'Art',
		compression: {width: 128, height: 128}
	},
	'Installation and Maintenance': {
		type: 'Art',
		compression: {width: 768, height: 768}
	},
	'Art Detail Print': {
		type: 'Art',
		showHeaders: true,
		showFooters: true,
		compression: {width: 512, height: 512}
	},
	'Image Report': {
		type: 'Art',
		showHeaders: true,
		showFooters: true,
		compression: {width: 768, height: 768}
	},
	'Contact Compact Print': {
		type: 'Contact',
		showTitle: true
	},
	'Contact Face Sheet': {
		type: 'Contact',
		showTitle: true,
	},
	'Art Appraisal Form': {
		type: 'Art',
		showHeaders: true,
		showFooters: true,
		compression: {width: 512, height: 512}
	},
	'Art Label Sheet': {
		type: 'Art',
		showHeaders: false,
		showFooters: false
	},
	'Contact Client Profile': {
		type: 'Contact',
		showTitle: true
	},
}
const orders = {
	'Art': [
		'Artist Last Name', 'Status',
		'Location', 'Retail Price',
		'Date',
		'Title',
		'Catalogue Raisonné',
		'Edition Number',
		'Height',
	],
	'Contact': ['Last Name', 'City', 'Country'],
	'Deal': ['Name', 'Type', 'Date Created']
}

export const headers = ['Lévy Gorvy Dayan',
	//  'Lévy Gorvy', 'Lévy Gorvy HK', 
	'None']
export const footers = ['Lévy Gorvy Dayan', 
	// 'No Address', 'New York', 'Hong Kong', 'London', 'Paris',
	'None']

const generateExportName = (template, selectedRows, extra) => {

	if (template === 'Art Fact Sheet') {

		const artPiece = selectedRows[0]
		if (extra.imagesToggle) {
			return `${artPiece.artist.last_name}_${artPiece.title}_${artPiece.year} with images.pdf`
		}

		return `${artPiece.artist.last_name}_${artPiece.title}_${artPiece.year}.pdf`
	}

	if (template === 'Contact Face Sheet') {
		return 'export.docx'
	}
	if (template === 'Art Label Sheet') {
		return 'export.docx'
	}
	if (template === 'Invoice') {
		return 'invoice.docx'
	}

	return 'export.pdf'
}
/**
 *
 * @param {string} template
 * @param {string | null} [header]
 * @param {string | null} [footer]
 * @param {any[]} selectedRows
 * @param {function} openSnackbar
 * @param {any} extra
 * @param {any} imageBounds
 * @param {function} setLoading
 * @returns
 */
export const getPdf = async (template, header, footer, selectedRows, openSnackbar, extra = null, imageBounds=null, setLoading) => {

	const type = exportTemplates[template]?.type
	const compression = imageBounds || exportTemplates[template]?.compression
	const url = new URL(EXPORT_URL, window.location)

	const ids = type ? (selectedRows ?? [])
		.filter(a => a.__typename === type)
		.map(a => a.id) : selectedRows.map(row => row.id)

	if (!ids.length) return

	if (setLoading) setLoading(true)
	const params = new URLSearchParams()
	params.set('template', camelCase(template))
	params.set('header', header)
	params.set('footer', footer)
	params.set('ids', ids)

	if (extra) params.set('extra', JSON.stringify(extra))
	if (compression) params.set('imageBounds', JSON.stringify(compression))


	url.search = params
	openSnackbar(severity.INFO, `Downloading. Please wait.`)

	return fetchWithAuth(url, { timeout: 15000 })
		.then(async response => {
			if (response.status !== 200) {
				throw(response)
			}
			const a = document.createElement("a")
			document.body.appendChild(a)
			a.style = "display: none"

			const blob = await response.blob()
			const url = window.URL.createObjectURL(blob)
			a.href = url
			a.download = generateExportName(template, selectedRows, extra)
			a.click()
			window.URL.revokeObjectURL(url)
			openSnackbar(severity.SUCCESS, `Download complete.`)
			if (setLoading) setLoading(false)
		})
		.catch(async err => {
			console.error(err)
			const resp = await err.json()
			const errorMessage = resp?.message ?? err.statusText
			openSnackbar(severity.ERROR, `Error: ${errorMessage}`)
			if (setLoading) setLoading(false)
		})
}

export default function PdfExport(props) {

	const urlParams = useParams()
	const { id } = urlParams

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	const includeArt = (props.selectedRows ?? [])
		.find(a => a.__typename === "Art")
	const includeContacts = (props.selectedRows ?? [])
		.find(a => a.__typename === "Contact")
	const templates = Object.entries(exportTemplates)
		.filter(([k, {type}]) => (type === 'Art' && includeArt) ||
			(type === 'Contact' && includeContacts))
		.map(([k, v]) => k)

	const [pdfExportTemplate, setPdfExportTemplate] = useState(camelCase(templates[0]))
	const [header, setHeader] = useState(headers[0])
	const [footer, setFooter] = useState(footers[0])
	const [title, setTitle] = useState("")
	const [extra, setExtra] = useState({})
	const [order, setOrder] = useState('')
	const [orderTwo, setOrderTwo] = useState('')

	const [loading, setLoading] = useState(false)

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	useEffect(() => {
		setPdfExportTemplate(templates[0])
		setOrder(orders[exportTemplates?.[templates?.[0]]?.type]?.[0])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.open, props.selectedRows])

	return <TransitionsModal
		className="pdf-export-modal"
		open={props.open}
		close={props.close}
	>
		<div style={{ minWidth: '35em', maxHeight: '45em', overflow: 'auto'}}>

			{props.loading ?
				<div style={{
					width: '100%',
					height: '12em',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<CircularProgress />
				</div> : <>

					<FormControl
						className="padded-select"
						style={{ width: '100%' }}
					>
						<Label id="type-label" disableAnimation shrink>
					Type
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="pdf-template"
							labelId="pdf-template-label"
							input={<Dropdown />}
							value={pdfExportTemplate}
							onChange={e => {
								setPdfExportTemplate(e.target.value)
								if (exportTemplates[e.target.value]?.type !=
							exportTemplates[pdfExportTemplate]?.type) {
									setOrder(orders[exportTemplates[e.target.value].type][0])
								}
							}}
						>
							{ templates.map(t =>
								<MenuItem key={t} value={t}>{ t }</MenuItem>
							) }
						</Select>
					</FormControl>
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						{ exportTemplates[pdfExportTemplate]?.showHeaders &&
					<FormControl className="padded-select"
						style={{width: '100%', paddingRight: '.5em'}}>
						<Label id="header-label" disableAnimation shrink>
							Header
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="currency"

							labelId="currency-label"
							input={<Dropdown />}
							value={header}
							onChange={e => setHeader(e.target.value)}
						>
							{headers.map(h => <MenuItem key={h} value={h}>{h}</MenuItem>)}
						</Select>
					</FormControl>
						}
						{ exportTemplates[pdfExportTemplate]?.showFooters &&
					<FormControl className="padded-select"
						style={{width: '100%'}}>
						<Label id="footer-label" disableAnimation shrink>Footer</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="currency"

							labelId="currency-label"
							input={<Dropdown />}
							value={footer}
							onChange={e => setFooter(e.target.value)}
						>
							{footers.map(h => <MenuItem key={h} value={h}>{h}</MenuItem>)}
						</Select>
					</FormControl>
						}
					</div>
					<div>
						{ exportTemplates[pdfExportTemplate]?.showTitle &&
					<FormControl className="fullWidth">
						<Label id="title-label" disableAnimation shrink>Title</Label>
						<TextBox
							id="export-title"
							value={title}
							onChange={ e=>setTitle(e.target.value) }
						>  </TextBox>
					</FormControl>
						}
					</div>
					{
						pdfExportTemplate === 'Art Appraisal Form' &&
						<ArtAppraisal onChange={val => setExtra(val)} />
					}
					{
						pdfExportTemplate === 'Art Detail Print' &&
						<ArtDetailOptions
							onChange={val => setExtra(val)}
							keyName={props.keyName}
							footer={footer}
						/>
					}
					{
						pdfExportTemplate === 'Contact Face Sheet' &&
						<FaceSheetModal
							onChange={val => setExtra(val)}
							options={props.options}
						/>
					}
					{
						pdfExportTemplate === 'Image Report' &&
						<ImageReportOptions onChange={val => setExtra(val)} />
					}
					{
						pdfExportTemplate === 'Contact Client Profile' &&
						<ClientProfile
							onChange={val => setExtra(val)}
							options={props.options}
						/>
					}
					{
						pdfExportTemplate === 'Inventory Report' &&
						<InventoryReportOptions onChange={val => setExtra(val)} />
					}
					{
						pdfExportTemplate === 'Framing' &&
						<FramingReportOptions onChange={val => setExtra(val)} />
					}
					{
						pdfExportTemplate === 'Art Label Sheet' &&
						<ArtLabelModal
							onChange = {val => setExtra(val)}
							fields = {extra}
						/>
					}
					<div style={{
						display: 'flex'
					}}>
						<FormControl className="fullWidth padded-select" style={{
							paddingRight: '1em'
						}}>
							<Label id="order-by-one" disableAnimation shrink>Primary Order</Label>
							<Select
								name="order-by-one"

								IconComponent={ExpandMoreRoundedIcon}
								labelId="order-by-one"
								input={<Dropdown />}
								value={order}
								onChange={e => setOrder(e.target.value)}
							>
								{orders[exportTemplates[pdfExportTemplate]?.type]
									?.map(t =>
										<MenuItem key={t} value={t}>{ t }</MenuItem>
									)
								}
							</Select>
						</FormControl>

						<FormControl className="fullWidth padded-select">
							<Label id="order-by-two" disableAnimation shrink>Secondary Order</Label>
							<Select
								name="order-by-two"

								IconComponent={ExpandMoreRoundedIcon}
								labelId="order-by-two"
								input={<Dropdown />}
								value={orderTwo || "None"}
								onChange={e => setOrderTwo(e.target.value)}
							>
								<MenuItem key={'None'} value={'None'}>None</MenuItem>
								{orders[exportTemplates[pdfExportTemplate]?.type]?.filter(e => e != order)
									?.map(t =>
										<MenuItem key={t} value={t}>{ t }</MenuItem>
									)
								}
							</Select>
						</FormControl>
					</div>
					<div style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						paddingTop: '1em',
						marginBottom: '0.1em'
					}}>
						<CancelButton
							variant="contained"
							onClick={props.close}
						>
					Cancel
						</CancelButton>
						<SubmitButton
							style={{ marginLeft: '1em' }}
							variant="contained"
							disabled={loading}
							onClick={() => {

								if (pdfExportTemplate === 'Contact Compact Print') {
									// Check if use selected Contact Compact Print without necessary permissions
									const canViewAdminConsole = userPermissions
										?.find(e => e.permission_id == permissions.ADMIN_CONSOLE &&
									e.permission_value_id == permissionValues.YES)

									const contactInfoPermission = userPermissions
										?.find(e => e.permission_id == permissions.CONTACT_INFORMATION  &&
									e.permission_value_id == permissionValues.YES)


									if (!canViewAdminConsole && !contactInfoPermission) {
										openSnackbar(severity.ERROR, "You do not have permission to export a Contact Compact Print.")
										return
									}

								}

								props.close()

								getPdf(
									pdfExportTemplate,
									header,
									footer,
									props.selectedRows,
									openSnackbar,
									{
										title,
										order,
										orderTwo,
										...{
											...extra,
											...props.options
										},
										entityId: id,
									},
									null,
									setLoading
								)
							}}
						>
					Download
						</SubmitButton>
					</div>

				</>
			}
		</div>
	</TransitionsModal>
}
